import {
  Directive,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";

@Directive({
  selector: "[appFeatureImage]",
})
export class FeatureImageDirective implements OnChanges {
  @Input("appFeatureImage") url: string;
  @HostBinding("class") class = "app-feature-image";
  @HostBinding("style") style: SafeStyle;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.url.currentValue) {
      this.style = this.sanitizer.bypassSecurityTrustStyle(
        `background-image:url('${changes.url.currentValue}')`
      );
    }
  }
}
