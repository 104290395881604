import { Injectable } from "@angular/core";
import { AngularFireAnalytics } from "@angular/fire/analytics";
import { AngularFireFunctions } from "@angular/fire/functions";
import { DatabaseService, ErrorsService, Program, User } from "@sophus/general";
import { chunk, get, keys } from "lodash-es";
import { environment } from "projects/client/src/environments/environment";
import { Observable, combineLatest, defer, of } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";

import { PaymentsService } from "../../core/services/payments.service";
import { SessionQuery } from "../../session/state/session.query";
import { ProgramsQuery } from "./programs.query";
import { ProgramsStore } from "./programs.store";

@Injectable({ providedIn: "root" })
export class ProgramsService {
  constructor(
    private programsStore: ProgramsStore,
    private programsQuery: ProgramsQuery,
    private sessionQuery: SessionQuery,
    private errorsService: ErrorsService,
    private dbService: DatabaseService,
    private afFunctions: AngularFireFunctions,
    private paymentsService: PaymentsService,
    private afAnalytics: AngularFireAnalytics
  ) {}

  public sync(): Observable<Program[]> {
    return this.sessionQuery.select("user").pipe(
      tap((user) => user || this.programsStore.reset()),
      switchMap((user: User) => {
        if (!user || !user.id) return of([]);
        const enrols = user.programs || {};
        const ids = keys(enrols).filter((id) =>
          get(enrols[id], "enroled", false)
        );
        return this.syncMany(ids);
      }),
      catchError((e) => this.errorsService.handleErrorWith(e, []))
    );
  }

  public syncOne(id: string): Observable<Program> {
    return this.dbService
      .sync(this.programsStore, "programs", (q) => q.where("id", "==", id))
      .pipe(
        switchMap(() => this.programsQuery.selectEntity(id)),
        catchError((e) => this.errorsService.handleError(e))
      );
  }

  public syncMany(ids: string[]): Observable<Program[]> {
    const observables: Observable<any>[] = [of([])];
    // chunk by groups of 10, this is a firestore limitation
    chunk(ids || [], 10).forEach((group) => {
      observables.push(
        this.dbService.sync(this.programsStore, "programs", (q) =>
          q.where("id", "in", group)
        )
      );
    });
    return combineLatest(...observables).pipe(
      switchMap(() => this.programsQuery.selectMany(ids)),
      catchError((e) => this.errorsService.handleErrorWith(e, []))
    );
  }

  public syncAvailable(): Observable<Program[]> {
    const org = environment?.org;
    return this.dbService
      .sync(this.programsStore, "programs", (q) => {
        return q.where(`organisations.${org}`, "==", true);
      })
      .pipe(
        switchMap(() =>
          this.programsQuery.selectAll({
            filterBy: (each) => get(each, `organisations.${org}`, false),
          })
        ),
        catchError((e) => this.errorsService.handleErrorWith(e, []))
      );
  }

  public fetchSignupPrograms(): Observable<any> {
    const app = environment?.app;
    const code = this.sessionQuery.getSignupCode();
    return this.afFunctions
      .httpsCallable("programs-getSignupPrograms")({ app, code })
      .pipe(
        map((programs) => programs as Program[]),
        tap((programs) => this.programsStore.upsertMany(programs)),
        catchError((e) => this.errorsService.handleError(e))
      );
  }

  public fetchAllForOrg(org: string): Observable<any> {
    return this.afFunctions
      .httpsCallable("programs-getOrgPrograms")({ org })
      .pipe(
        map((programs) => programs as Program[]),
        tap((programs) => this.programsStore.upsertMany(programs)),
        catchError((e) => this.errorsService.handleError(e))
      );
  }

  public enrolInProgram(program: string): Observable<any> {
    const org = get(this.sessionQuery.getValue(), "signupOrg", environment.org);
    return this.afFunctions
      .httpsCallable("programs-enrolWithCard")({ id: program, org })
      .pipe(catchError((e) => this.errorsService.handleError(e)));
  }

  public enrolWithIAP(program: string, sku: string): Observable<any> {
    const org = get(this.sessionQuery.getValue(), "signupOrg", environment.org);
    return defer(() => this.paymentsService.purchaseProductWithIAP(sku)).pipe(
      switchMap(() => {
        return this.afFunctions.httpsCallable("programs-enrolWithIAP")({
          id: program,
          org,
        });
      }),
      catchError((e) => this.errorsService.handleError(e))
    );
  }

  public enrolWithInvite(code: string): Observable<any> {
    return this.afFunctions
      .httpsCallable("invites-useCode")({ code })
      .pipe(catchError((e) => this.errorsService.handleError(e)));
  }

  public updateKeyDate(program: string, date: number): Observable<any> {
    const user = this.sessionQuery.getValue()?.user?.id;
    return this.afFunctions
      .httpsCallable("programs-updateDate")({ program, user, date })
      .pipe(catchError((e) => this.errorsService.handleError(e)));
  }

  public fetchSingleForCode(code: string) {
    return this.afFunctions
      .httpsCallable("invites-getInviteProgram")({ code })
      .pipe(catchError((e) => this.errorsService.handleError(e)));
  }
}
