<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="close()">
        <ion-icon md="close" ios="close-sharp" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <ion-slides pager="true" (ionSlideDidChange)="change()">
    <ion-slide>
      <img
        *ngIf="environment?.app"
        src="assets/img/{{ environment.app }}/undraw/chat.svg"
        class="ion-margin-vertical"
      />
      <h5>Request a personalized meal plan</h5>
      <ion-text color="medium">
        We'll contact you with a short questionnaire that tells us more about
        you, your goals, and your preferences
      </ion-text>
    </ion-slide>
    <ion-slide>
      <img
        *ngIf="environment?.app"
        src="assets/img/{{ environment.app }}/undraw/healthy-options.svg"
        class="ion-margin-vertical"
      />
      <h5>Receive tailored support</h5>
      <ion-text color="medium">
        Our dietitians will create a meal guide for your nutrition goals, taste
        preferences, and lifestyle
      </ion-text>
      <ion-text class="ion-margin-vertical" color="medium">
        Your guide will be accompanied by dietitian-created recipes
      </ion-text>
    </ion-slide>
    <ion-slide>
      <img
        *ngIf="environment?.app"
        src="assets/img/{{ environment.app }}/undraw/shopping-app.svg"
        class="ion-margin-vertical"
      />
      <h5>Ready to get started?</h5>
      <ion-text color="medium">
        You will receive your meal plan within 48 hours of completing your
        questionnaire
      </ion-text>
      <ion-text class="ion-margin-vertical" color="medium">
        Meal plans cost an additional $80 AUD
      </ion-text>
      <ion-button
        shape="round"
        color="primary"
        class="ion-margin-top"
        (click)="requestMessages()"
        [disabled]="loading"
      >
        <ion-label *ngIf="!loading">Request A Meal Plan</ion-label>
        <ion-spinner *ngIf="loading" name="crescent"></ion-spinner>
      </ion-button>
    </ion-slide>
  </ion-slides>
</ion-content>
<ion-footer class="ion-no-border">
  <ion-toolbar color="white" class="ion-text-center">
    <ion-buttons *ngIf="!end" slot="end">
      <ion-button shape="round" color="primary" (click)="next()">
        <ion-label>Next</ion-label>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
