<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="close()">
        <ion-icon md="close" ios="close-sharp" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <ion-slides pager="true" (ionSlideDidChange)="change()">
    <ion-slide>
      <img
        *ngIf="environment?.app"
        src="assets/img/{{ environment.app }}/undraw/chatting.svg"
        class="ion-margin-vertical"
      />
      <h5>A dietitian at your fingertips</h5>
      <ion-text color="medium">
        Messaging gives you access to your dietitian for real-time support
      </ion-text>
    </ion-slide>
    <ion-slide>
      <img
        *ngIf="environment?.app"
        src="assets/img/{{ environment.app }}/undraw/empty-cart.svg"
        class="ion-margin-vertical"
      />
      <h5>Where and when you need</h5>
      <ion-text color="medium">
        At home, the grocery store or at a restaurant, your dietitian is there
        to guide you to reach your goals
      </ion-text>
    </ion-slide>
    <ion-slide>
      <img
        *ngIf="environment?.app"
        src="assets/img/{{ environment.app }}/undraw/choice.svg"
        class="ion-margin-vertical"
      />
      <h5>Choose support when it suits you</h5>
      <ion-text color="medium">
        Opt in for your twelve week program for just $29 AUD per week and have a
        dietitian in your pocket wherever you go
      </ion-text>
      <ion-text class="ion-margin-vertical" color="medium">
        Simply tap here and someone from our team will be in touch to set you up
      </ion-text>
      <ion-button
        shape="round"
        color="primary"
        class="ion-margin-top"
        (click)="requestMessages()"
        [disabled]="loading"
      >
        <ion-label *ngIf="!loading">Get Personal Support</ion-label>
        <ion-spinner *ngIf="loading" name="crescent"></ion-spinner>
      </ion-button>
    </ion-slide>
  </ion-slides>
</ion-content>
<ion-footer class="ion-no-border">
  <ion-toolbar color="white" class="ion-text-center">
    <ion-buttons *ngIf="!end" slot="end">
      <ion-button shape="round" color="primary" (click)="next()">
        <ion-label>Next</ion-label>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
