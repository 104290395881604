import { Injectable } from "@angular/core";
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";
import { Upload } from "@sophus/general";

export interface UploadsState extends EntityState<Upload> {}

@Injectable({ providedIn: "root" })
@StoreConfig({ name: "uploads" })
export class UploadsStore extends EntityStore<UploadsState> {
  constructor() {
    super();
  }
}
