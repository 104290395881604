import { ErrorHandler, NgModule } from "@angular/core";
import { AngularFireModule } from "@angular/fire";
import {
  AngularFireAnalyticsModule,
  ScreenTrackingService,
  UserTrackingService,
} from "@angular/fire/analytics";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { AkitaNgDevtools } from "@datorama/akita-ngdevtools";
import { InAppPurchase2 } from "@ionic-native/in-app-purchase-2/ngx";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { StackdriverErrorService } from "@sophus/general";

import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CoreModule } from "./core/core.module";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    CoreModule,
    AkitaNgDevtools.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      provide: ErrorHandler,
      useFactory: StackdriverErrorServiceFactory,
    },
    InAppPurchase2,
    ScreenTrackingService,
    UserTrackingService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function StackdriverErrorServiceFactory() {
  return new StackdriverErrorService(
    environment.firebase.apiKey,
    environment.firebase.projectId,
    environment.app,
    environment.production
  );
}
