import { Directive, HostListener } from "@angular/core";

@Directive({
  selector: "[appFixInvalid]",
})
export class FixInvalidDirective {
  constructor() {}

  @HostListener("ionFocus", ["$event.target.parentNode"])
  fixInvalidOnFocus(item: any) {
    if (
      item.classList.contains("ion-pristine") &&
      item.classList.contains("ion-invalid")
    ) {
      item.classList.remove("ion-invalid");
    }
  }
}
