import { Injectable } from "@angular/core";
import { QueryEntity } from "@datorama/akita";
import { ProgramsStore, ProgramsState } from "./programs.store";
import { SessionQuery } from "../../session/state/session.query";
import { switchMap, tap, map } from "rxjs/operators";
import { of, Observable } from "rxjs";
import { includes, filter, get, keys } from "lodash-es";
import { Program, User } from "@sophus/general";

@Injectable({ providedIn: "root" })
export class ProgramsQuery extends QueryEntity<ProgramsState> {
  constructor(
    protected store: ProgramsStore,
    private sessionQuery: SessionQuery
  ) {
    super(store);
  }

  public selectRecommended(): Observable<Program> {
    return this.selectAll({
      filterBy: [(each) => get(each, "meta.signup", false) === "recommended"],
    }).pipe(map((selected) => selected[0]));
  }

  public selectStarters(): Observable<Program[]> {
    return this.selectAll({
      filterBy: [(each) => get(each, "meta.signup", false) === "starter"],
    });
  }

  public selectTrials(): Observable<Program[]> {
    return this.selectAll({
      filterBy: [(each) => get(each, "meta.signup", false) === "trial"],
    });
  }

  public selectAllForOrg(org: string): Observable<Program[]> {
    return this.selectAll({
      filterBy: [(each) => get(each, `organisations.${org}`, false)],
    });
  }

  public selectSignup(): Observable<Program> {
    return this.sessionQuery
      .select("signupProgram")
      .pipe(switchMap((id) => this.selectEntity(id)));
  }

  public selectAllForSessionUser(): Observable<Program[]> {
    return this.sessionQuery.select("user").pipe(
      switchMap((user) => {
        if (!user) return of([]);
        const enrols = user.programs || {};
        const ids = keys(enrols).filter((id) =>
          get(enrols[id], "enroled", false)
        );
        return this.selectAll({
          filterBy: [(each) => includes(ids, each.id)],
        });
      })
    );
  }
}
