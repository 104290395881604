<ion-app [ngClass]="environment?.app">
  <ion-split-pane contentId="main" [disabled]="!(showMenu$ | async)">
    <ion-menu contentId="main" swipeGesture="false">
      <ion-header>
        <ion-toolbar color="tertiary">
          <img
            *ngIf="environment?.app"
            src="/assets/img/{{ environment.app }}/logo-w.svg"
            class="app-logo-image"
          />
        </ion-toolbar>
      </ion-header>
      <ion-content color="tertiary" class="ion-padding-horizontal">
        <ion-list lines="none">
          <ion-list-header color="tertiary">Menu</ion-list-header>

          <ion-item
            routerLink="/tabs/home"
            routerLinkActive="active"
            detail="false"
            (click)="close()"
            color="tertiary"
          >
            <ion-icon name="home" ios="home-sharp" slot="start"></ion-icon>
            <ion-label>Home</ion-label>
          </ion-item>

          <ng-container *ngIf="(programs$ | async)?.length > 1; else single">
            <ion-item
              detail="false"
              (click)="toggle = !toggle"
              color="tertiary"
              button="true"
            >
              <ion-icon name="book" ios="book-sharp" slot="start"></ion-icon>
              <ion-label>My Programs</ion-label>
              <ion-icon
                *ngIf="!toggle"
                slot="end"
                md="caret-down"
                ios="caret-down-sharp"
                size="small"
              ></ion-icon>
              <ion-icon
                *ngIf="toggle"
                slot="end"
                md="caret-up"
                ios="caret-up-sharp"
                size="small"
              ></ion-icon>
            </ion-item>

            <ng-container *ngIf="toggle">
              <ng-container *ngFor="let program of programs$ | async">
                <ion-item
                  routerLink="/tabs/programs/{{ program?.id }}"
                  color="tertiary"
                  (click)="close()"
                >
                  <ion-icon slot="start"></ion-icon>
                  <ion-label color="medium">{{ program?.title }}</ion-label>
                </ion-item>
              </ng-container>
            </ng-container>
          </ng-container>

          <ng-template #single>
            <ion-item
              detail="false"
              routerLink="/tabs/programs"
              routerLinkActive="active"
              color="tertiary"
              (click)="close()"
            >
              <ion-icon name="book" ios="book-sharp" slot="start"></ion-icon>
              <ion-label>My Program</ion-label>
            </ion-item>
          </ng-template>

          <ion-item
            routerLink="/tabs/messages"
            routerLinkActive="active"
            detail="false"
            (click)="close()"
            color="tertiary"
          >
            <ion-icon
              name="chatbubbles"
              ios="chatbubbles-sharp"
              slot="start"
            ></ion-icon>
            <ion-label>Messages</ion-label>
          </ion-item>
          <ion-item
            routerLink="/tabs/journal"
            routerLinkActive="active"
            detail="false"
            (click)="close()"
            color="tertiary"
          >
            <ion-icon
              name="journal"
              ios="journal-sharp"
              slot="start"
            ></ion-icon>
            <ion-label>Journal</ion-label>
          </ion-item>
          <ion-item
            routerLink="/tabs/store"
            routerLinkActive="active"
            detail="false"
            (click)="close()"
            color="tertiary"
          >
            <ion-icon md="library" ios="library-sharp" slot="start"></ion-icon>
            <ion-label>Get More</ion-label>
          </ion-item>
        </ion-list>
        <ion-list lines="none">
          <ion-list-header color="tertiary">Account</ion-list-header>
          <ion-item
            routerLink="/settings"
            routerLinkActive="active"
            detail="false"
            (click)="close()"
            color="tertiary"
          >
            <ion-icon
              name="settings"
              ios="settings-sharp"
              slot="start"
            ></ion-icon>
            <ion-label>Settings</ion-label>
          </ion-item>
          <ion-item
            detail="false"
            button="true"
            (click)="signOut()"
            (click)="close()"
            color="tertiary"
          >
            <ion-icon
              name="log-out"
              ios="log-out-sharp"
              slot="start"
            ></ion-icon>
            <ion-label>Sign Out</ion-label>
          </ion-item>
        </ion-list>
        <ion-list lines="none">
          <ion-list-header color="tertiary">Get Help</ion-list-header>
          <ion-item
            detail="false"
            color="tertiary"
            (click)="showFeedbackSurvey()"
          >
            <ion-icon md="bug" ios="bug-sharp" slot="start"></ion-icon>
            <ion-label>Feedback</ion-label>
          </ion-item>
          <ion-item
            routerLink="/settings/contact"
            detail="false"
            color="tertiary"
            (click)="close()"
          >
            <ion-icon md="call" ios="call-sharp" slot="start"></ion-icon>
            <ion-label>Contact Us</ion-label>
          </ion-item>
          <ion-item
            *ngIf="environment?.website"
            [href]="environment.website"
            target="_blank"
            detail="false"
            color="tertiary"
            (click)="close()"
          >
            <ion-icon md="globe" ios="globe-sharp" slot="start"></ion-icon>
            <ion-label>Website</ion-label>
          </ion-item>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
