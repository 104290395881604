import {} from "firebase/app";

import { Injectable } from "@angular/core";
import { AngularFireAnalytics } from "@angular/fire/analytics";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireFunctions } from "@angular/fire/functions";
import { NavController } from "@ionic/angular";
import {
  DatabaseService,
  ErrorsService,
  User,
  createUser,
} from "@sophus/general";
import { cloneDeep, forEach, get, merge, pick, union } from "lodash-es";
import { environment } from "projects/client/src/environments/environment";
import { Observable, defer, empty, of } from "rxjs";
import { catchError, finalize, map, switchMap, tap } from "rxjs/operators";

import { SessionQuery } from "./session.query";
import { SessionStore } from "./session.store";

declare global {
  interface Window {
    _sva: any;
  }
}

@Injectable({ providedIn: "root" })
export class SessionService {
  public authState$: Observable<any>;

  constructor(
    private sessionStore: SessionStore,
    private afAuth: AngularFireAuth,
    private errorsService: ErrorsService,
    private navCtrl: NavController,
    private dbService: DatabaseService,
    private afFunctions: AngularFireFunctions,
    private sessionQuery: SessionQuery,
    private afAnalytics: AngularFireAnalytics
  ) {
    this.authState$ = this.afAuth.authState;
  }

  public sync(): Observable<any> {
    return this.authState$.pipe(
      tap((auth) => this.setAuthDetails(auth)),
      switchMap((auth) => this.retreiveUser(auth)),
      tap((user) => this.setUser(user)),
      finalize(() => this.signOut()),
      catchError((e) => this.errorsService.handleError(e))
    );
  }

  public signIn(email: string, password: string): Observable<boolean> {
    return defer(() =>
      this.afAuth.signInWithEmailAndPassword(email, password)
    ).pipe(
      map((auth) => !!auth),
      catchError((e) => this.errorsService.handleError(e))
    );
  }

  public async signOut(): Promise<void> {
    await this.navCtrl.navigateRoot("/session");
    return this.afAuth.signOut();
  }

  public resetPassword(email: string): Observable<void> {
    return defer(() => this.afAuth.sendPasswordResetEmail(email)).pipe(
      catchError((e) => this.errorsService.handleError(e))
    );
  }

  public checkAuthState(): Observable<boolean> {
    return this.authState$.pipe(
      map((auth) => !!auth),
      tap((auth) => auth || this.signOut()),
      catchError((e) => this.errorsService.handleErrorWith(e, false))
    );
  }

  public signupWithEmailAndPassword(
    name: string,
    email: string,
    password: string
  ): Observable<any> {
    return defer(() => {
      return this.afAuth.createUserWithEmailAndPassword(
        email.toLowerCase(),
        password
      );
    }).pipe(
      switchMap((credential) => {
        return defer(() =>
          credential.user
            .updateProfile({ displayName: name })
            .then(() => credential.user)
        );
      }),
      catchError((e) => this.errorsService.handleError(e))
    );
  }

  private setAuthDetails(auth: any): void {
    if (!auth) return this.sessionStore.update({ id: null });
    return this.sessionStore.update({ id: auth.uid });
  }

  public createUser(id: string, data: Partial<User>): Observable<void> {
    const organisations = { [environment.org]: { member: true } };
    const signupOrg = get(this.sessionQuery.getValue(), "signupOrg", false);
    if (signupOrg) organisations[signupOrg] = { member: true };
    if (data?.email) data.email = data.email.toLowerCase();
    const user = createUser({
      id,
      ...data,
      created: new Date().getTime(),
      organisations,
      programs: {},
      flags: {},
      platform: environment?.app,
    });
    return this.afFunctions
      .httpsCallable("users-createUser")(user)
      .pipe(catchError((e) => this.errorsService.handleError(e)));
  }

  private retreiveUser(auth: any): Observable<User> {
    if (!auth) return of(null);
    return this.dbService
      .select(`users/${auth.uid}`)
      .pipe(map((user) => user as User));
  }

  private setUser(user: User): void {
    this.sessionStore.update({ user });
    this.setServicateVisitorTraits(user);
    this.logUserAnalytics(user);
  }

  public updateUser(id: string, data: Partial<User>): Observable<void> {
    data = pick(data, [
      "firstName",
      "lastName",
      "dob",
      "gender",
      "timezone",
      "image",
      "devices",
      "flags",
      "lastMessage",
    ]);
    return defer(() => this.dbService.update(`users/${id}`, data)).pipe(
      tap(() => {
        const old = this.sessionStore.getValue().user || {};
        const user = merge(old, data) as User;
        return this.sessionStore.update({ user });
      }),
      catchError((e) => this.errorsService.handleError(e))
    );
  }

  public updateUserEmail(id: string, email: string): Observable<any> {
    return this.afFunctions
      .httpsCallable("users-updateEmail")({ id, email })
      .pipe(catchError((e) => this.errorsService.handleError(e)));
  }

  public updateUserPhoneNumber(
    id: string,
    data: Partial<User>
  ): Observable<any> {
    data = pick(data, ["country", "phone"]);
    return this.afFunctions
      .httpsCallable("users-updatePhone")({ id, ...data })
      .pipe(catchError((e) => this.errorsService.handleError(e)));
  }

  public sendContactMail(subject: string, message: string): Observable<any> {
    const user = this.sessionStore.getValue().user;
    if (!user) return empty();
    const data = merge(user, { subject, message });
    return this.afFunctions
      .httpsCallable("emails-sendContact")(data)
      .pipe(catchError((e) => this.errorsService.handleError(e)));
  }

  public sendMessagesRequest(): Observable<any> {
    const user = this.sessionStore.getValue().user;
    if (!user) return empty();
    const data = merge(user);
    return this.afFunctions
      .httpsCallable("emails-sendRequest")(data)
      .pipe(catchError((e) => this.errorsService.handleError(e)));
  }

  public sendMealplanRequest(): Observable<any> {
    const user = this.sessionStore.getValue().user;
    if (!user) return empty();
    const data = merge(user);
    return this.afFunctions
      .httpsCallable("emails-sendMealplan")(data)
      .pipe(catchError((e) => this.errorsService.handleError(e)));
  }

  public updateSignupCode(question: number, answer: string): void {
    let code = cloneDeep(this.sessionStore.getValue().signupCode);
    code[question] = answer;
    this.sessionStore.update({ signupCode: code });
  }

  public updatePaymentMethod(id: string, token: string): Observable<any> {
    return this.afFunctions
      .httpsCallable("users-updatePayment")({ id, token })
      .pipe(catchError((e) => this.errorsService.handleError(e)));
  }

  public addToDeviceTokens(token: string): Observable<any> {
    const user = this.sessionStore.getValue().user;
    if (!user) return empty();
    const data = merge(user, { devices: union(user.devices, [token]) });
    return this.updateUser(user.id, data);
  }

  private logUserAnalytics(user: User) {
    if (!user) return;
    const orgs = user.organisations || {};
    forEach(orgs, (org, id) => {
      if (!org.member) return;
      this.afAnalytics
        .logEvent("org_session", { org: id })
        .catch(console.error);
    });
  }

  private setServicateVisitorTraits(user: User) {
    if (!window._sva) return;
    if (!user) return window._sva.destroyVisitor();
    window._sva.setVisitorTraits({
      first_name: user.firstName,
      last_name: user.lastName,
      email: user.email,
      country: user.country,
      timezone: user?.timezone,
      gender: user?.gender,
      has_coaching: user?.messaging,
    });
  }
}
