export const environment = {
  production: false,
  app: "sophus",
  shade: "#6fac92",
  org: "iUHMoVj1dBG4qjWqwyaZ",
  name: "Sophus",
  bundle: "com.sophusnutrition.sophus",
  website: "https://sophus.com.au",
  privacyPolicy: "https://www.sophus.com.au/privacy-policy",
  appStoreUrl: "https://apps.apple.com/us/app/sophus-nutrition/id1474937545",
  playStoreUrl:
    "https://play.google.com/store/apps/details?id=com.sophusnutrition.sophus",
  facebook: "https://www.facebook.com/sophusnutrition/",
  instagram: "https://www.instagram.com/sophusnutrition/",
  welcomeVideoId: "uJ0OuZD2Wkw",
  firebase: {
    apiKey: "AIzaSyAM7lR84C5atIzIQ1ocnCQdnNh_dgk0Ov8",
    authDomain: "sophus-nutrition-dev.firebaseapp.com",
    databaseURL: "https://sophus-nutrition-dev.firebaseio.com",
    projectId: "sophus-nutrition-dev",
    storageBucket: "sophus-nutrition-dev.appspot.com",
    messagingSenderId: "959359868637",
    appId: "1:959359868637:web:231932b5a82212d2",
  },
  stripe: "pk_test_bVrwooTAyid9h7JS1kx1N5RE",
};
