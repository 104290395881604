import { stringify } from "querystring";

import { Injectable } from "@angular/core";
import { Store, StoreConfig } from "@datorama/akita";
import { User } from "@sophus/general";
import { environment } from "projects/client/src/environments/environment";

export interface SessionState {
  id: string;
  user: User;
  signupCode: { [question: number]: string };
  signupProgram: string;
  inviteCode: string;
  signupOrg: string;
  signupAccess: string;
  signupRedirect: string;
}

export function createInitialState(): SessionState {
  return {
    id: null,
    user: null,
    signupCode: {},
    signupProgram: null,
    inviteCode: null,
    signupOrg: null,
    signupAccess: null,
    signupRedirect: null,
  };
}

@Injectable({ providedIn: "root" })
@StoreConfig({ name: "session" })
export class SessionStore extends Store<SessionState> {
  constructor() {
    super(createInitialState());
  }
}
