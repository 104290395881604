import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { GeneralModule } from "@sophus/general";
import { TruncatePipe } from "projects/admin/src/app/shared/pipes/truncate/truncate.pipe";

import { FeatureImageDirective } from "./directives/feature-image/feature-image.directive";
import { FixInvalidDirective } from "./directives/fix-invalid/fix-invalid.directive";
import { DownloadPipe } from "./pipes/download/download.pipe";
import { FormatBytesPipe } from "./pipes/format-bytes/format-bytes.pipe";
import { Html2TextPipe } from "./pipes/html2text/html2text.pipe";
import { PreviewPipe } from "./pipes/preview/preview.pipe";
import { ReplacePipe } from "./pipes/replace/replace.pipe";
import { MealplanRequestViewComponent } from "./views/mealplan-request-view/mealplan-request-view.component";
import { MessagesRequestViewComponent } from "./views/messages-request-view/messages-request-view.component";
import { UpdateRequiredViewComponent } from "./views/update-required-view/update-required-view.component";

@NgModule({
  declarations: [
    FixInvalidDirective,
    FeatureImageDirective,
    FormatBytesPipe,
    ReplacePipe,
    TruncatePipe,
    UpdateRequiredViewComponent,
    PreviewPipe,
    DownloadPipe,
    Html2TextPipe,
    MealplanRequestViewComponent,
    MessagesRequestViewComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    HttpClientModule,
    FormsModule,
    GeneralModule,
  ],
  exports: [
    GeneralModule,
    IonicModule,
    FixInvalidDirective,
    FeatureImageDirective,
    FormatBytesPipe,
    HttpClientModule,
    TruncatePipe,
    PreviewPipe,
    DownloadPipe,
    Html2TextPipe,
  ],
  providers: [ReplacePipe, TruncatePipe, PreviewPipe, DownloadPipe],
})
export class SharedModule {}
