import { Injectable } from "@angular/core";
import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";
import { Program } from "@sophus/general";

export interface ProgramsState extends EntityState<Program> {}

@Injectable({ providedIn: "root" })
@StoreConfig({ name: "programs", resettable: true })
export class ProgramsStore extends EntityStore<ProgramsState> {
  constructor() {
    super();
  }
}
