import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "html2text",
})
export class Html2TextPipe implements PipeTransform {
  transform(value: string): string {
    return value ? value.replace(/(&nbsp;|<([^>]+)>)/gi, "") : "";
  }
}
