import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

import { AuthGuard } from "./core/guards/auth/auth.guard";
import { UpdateRequiredViewComponent } from "./shared/views/update-required-view/update-required-view.component";

const routes: Routes = [
  { path: "", redirectTo: "tabs", pathMatch: "full" },
  {
    path: "update",
    component: UpdateRequiredViewComponent,
  },
  {
    path: "join",
    loadChildren: () => import("./join/join.module").then((m) => m.JoinModule),
  },
  {
    path: "session",
    loadChildren: () =>
      import("./session/session.module").then((m) => m.SessionModule),
  },
  {
    path: "tabs",
    loadChildren: () => import("./tabs/tabs.module").then((m) => m.TabsModule),
    canActivate: [AuthGuard],
  },
  {
    path: "settings",
    loadChildren: () =>
      import("./settings/settings.module").then((m) => m.SettingsModule),
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    relativeLinkResolution: 'legacy'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
