import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  NotificationPermissionResponse,
  Plugins,
  PushNotification,
  PushNotificationActionPerformed,
} from "@capacitor/core";
import { ModalController, Platform, ToastController } from "@ionic/angular";
import { FCM } from "capacitor-fcm";
import { Observable } from "rxjs";
import { distinctUntilChanged, filter, map, take, tap } from "rxjs/operators";

import { SessionQuery } from "../../session/state/session.query";
import { SessionService } from "../../session/state/session.service";

const { PushNotifications, LocalNotifications } = Plugins;
const fcm = new FCM();

@Injectable({ providedIn: "root" })
export class PushService {
  constructor(
    private router: Router,
    private toastCtrl: ToastController,
    private platform: Platform,
    private sessionService: SessionService,
    private sessionQuery: SessionQuery,
    private modalCtrl: ModalController
  ) {}

  public sync(): Observable<any> {
    return this.sessionQuery.select("user").pipe(
      filter((user) => !!user?.id),
      map((user) => user.id),
      distinctUntilChanged(),
      tap(async () => {
        if (!this.platform.is("capacitor")) return;
        await PushNotifications.register();
        const token = (await fcm.getToken())?.token;
        this.sessionService
          .addToDeviceTokens(token)
          .pipe(take(1))
          .subscribe(() => this.listeners());
      })
    );
  }

  public async testPermission(): Promise<boolean> {
    if (!this.platform.is("capacitor")) return;
    return (await LocalNotifications.areEnabled())?.value;
  }

  public requestPermission(): Promise<NotificationPermissionResponse> {
    if (!this.platform.is("capacitor")) return;
    return PushNotifications.requestPermission();
  }

  public async removeListeners(): Promise<void> {
    if (!this.platform.is("capacitor")) return;
    return PushNotifications.removeAllListeners();
  }

  private listeners(): void {
    PushNotifications.addListener(
      "pushNotificationReceived",
      this.receive.bind(this)
    );
    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      this.action.bind(this)
    );
  }

  async receive(n: PushNotification): Promise<any> {
    const { type } = n.data;
    if (type == "message") return this.receiveMessage();
    if (type == "journal") return this.receiveJournal();
    if (type == "content") return this.receiveContent(n.data);
    return false;
  }

  async action(a: PushNotificationActionPerformed): Promise<boolean> {
    const { type } = a.notification.data;
    if (type == "message") return this.actionMessage();
    if (type == "journal") return this.actionJournal();
    if (type == "content") return this.actionContent(a.notification.data);
    return false;
  }

  public async receiveMessage(): Promise<void> {
    const toast = await this.toastCtrl.create({
      message: "New Message",
      duration: 3000,
      buttons: [
        {
          text: "Show",
          handler: () => this.router.navigateByUrl("/tabs/messages"),
        },
      ],
    });
    return toast.present();
  }

  actionMessage(): Promise<boolean> {
    return this.router.navigateByUrl("/tabs/messages");
  }

  public async receiveJournal(): Promise<void> {
    const toast = await this.toastCtrl.create({
      message: "New Journal Reply",
      duration: 3000,
      buttons: [
        {
          text: "Show",
          handler: () => this.router.navigateByUrl("/tabs/journal"),
        },
      ],
    });
    return toast.present();
  }

  public actionJournal(): Promise<boolean> {
    return this.router.navigateByUrl("/tabs/journal");
  }

  async receiveContent(data: any): Promise<void> {
    const { program, content } = data;
    if (!program || !content) return;
    const toast = await this.toastCtrl.create({
      message: "New Content",
      duration: 3000,
      buttons: [
        {
          text: "View",
          handler: () => {
            const route = `/tabs/programs/${program}/contents/${content}`;
            return this.router.navigateByUrl(route);
          },
        },
      ],
    });
    return toast.present();
  }

  async actionContent(data: any): Promise<boolean> {
    const { program, content } = data;
    if (!program || !content) return;
    const route = `/tabs/programs/${program}/contents/${content}`;
    return this.router.navigateByUrl(route);
  }

  public async showLocal(title: string, body: string): Promise<void> {
    if (!this.platform.is("capacitor")) return;
    await LocalNotifications.schedule({
      notifications: [
        {
          title,
          body,
          id: 0,
          schedule: { at: new Date(new Date().getTime() + 1000) },
        },
      ],
    });
  }
}
