import { Pipe, PipeTransform } from "@angular/core";
import { take } from "rxjs/operators";
import { UploadsService } from "../../../uploads/state/uploads.service";
import { UploadsQuery } from "../../../uploads/state/uploads.query";

@Pipe({
  name: "download",
})
export class DownloadPipe implements PipeTransform {
  constructor(
    private uploadsService: UploadsService,
    private uploadsQuery: UploadsQuery
  ) {}

  transform(value: any, ...args: any[]): any {
    // try and sync the matching upload
    if (value) {
      this.uploadsService
        .sync(value)
        .pipe(take(1))
        .subscribe();
    }
    // return the query from store, and fetch a download url
    // this will update once synced or return nothing
    return this.uploadsQuery.selectDownloadURL(value);
  }
}
