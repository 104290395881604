import { Component, OnInit, ViewChild } from "@angular/core";
import { IonSlides, ModalController } from "@ionic/angular";
import { NotifyService } from "@sophus/general";
import { environment } from "projects/client/src/environments/environment";
import { finalize } from "rxjs/operators";
import { SessionService } from "../../../session/state/session.service";

@Component({
  selector: "app-messages-request-view",
  templateUrl: "./messages-request-view.component.html",
  styleUrls: ["./messages-request-view.component.scss"],
})
export class MessagesRequestViewComponent implements OnInit {
  public end: boolean;
  public loading: boolean;
  public environment: any;

  @ViewChild(IonSlides) slides: IonSlides;

  constructor(
    private modalCtrl: ModalController,
    private sessionService: SessionService,
    private notifyService: NotifyService
  ) {}

  public ngOnInit(): void {
    this.environment = environment;
    this.loading = false;
    this.end = false;
  }

  public close(): Promise<boolean> {
    return this.modalCtrl.dismiss();
  }

  public next(): Promise<void> {
    return this.slides.slideNext();
  }

  public async change(): Promise<void> {
    this.end = await this.slides.isEnd();
  }

  public requestMessages(): void {
    this.loading = true;
    this.sessionService
      .sendMessagesRequest()
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(() => {
        const message = `Request sent. Someone from our team will be in touch soon!`;
        this.notifyService.showMessageToast(message);
        this.modalCtrl.dismiss();
      });
  }
}
