import { Injectable } from "@angular/core";
import { Query } from "@datorama/akita";
import { User } from "@sophus/general";
import { values } from "lodash-es";
import { Observable } from "rxjs";

import { SessionState, SessionStore } from "./session.store";

@Injectable({ providedIn: "root" })
export class SessionQuery extends Query<SessionState> {
  constructor(protected store: SessionStore) {
    super(store);
  }

  public getSignupCode(): string {
    const signup = this.store.getValue().signupCode;
    const code = values(signup).join("");
    return code;
  }

  public selectSessionUser(): Observable<User> {
    return this.select("user");
  }
}
