import { Pipe, PipeTransform } from "@angular/core";
import { filter, take, map } from "rxjs/operators";
import { UploadsQuery } from "../../../uploads/state/uploads.query";
import { UploadsService } from "../../../uploads/state/uploads.service";

@Pipe({ name: "preview" })
export class PreviewPipe implements PipeTransform {
  constructor(
    private uploadsService: UploadsService,
    private uploadsQuery: UploadsQuery
  ) {}

  transform(value: any, ...args: any[]): any {
    // if we have a value, try and sync it to store
    if (value) {
      this.uploadsService
        .sync(value)
        .pipe(take(1))
        .subscribe();
    }
    // select the query from store and filter to the image url
    return this.uploadsQuery.selectEntity(value).pipe(
      filter((upload) => upload && !!upload.image),
      map((upload) => upload.image)
    );
  }
}
