import { Injectable } from "@angular/core";
import { InAppPurchase2 } from "@ionic-native/in-app-purchase-2/ngx";
import { Platform } from "@ionic/angular";
import { PRICE_LIST } from "@sophus/general";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class PaymentsService {
  public prices: { [sku: string]: number };

  constructor(public iap: InAppPurchase2, private platform: Platform) {
    this.prices = PRICE_LIST || {};
  }

  public async init(): Promise<void> {
    await this.registerProductsWithIAP();
    await this.refreshProductsFromIAP();
  }

  public async registerProductsWithIAP(): Promise<void> {
    await this.platform.ready();
    Object.keys(this.prices).forEach((sku) => {
      const id = `${environment.bundle}.${sku}`;
      this.iap.register({ id, type: this.iap.CONSUMABLE });
    });
  }

  public async refreshProductsFromIAP(): Promise<void> {
    await this.platform.ready();
    this.iap.refresh();
  }

  public async purchaseProductWithIAP(sku: string): Promise<boolean> {
    await this.platform.ready();
    const result = new Promise<boolean>((res, rej) => {
      const id = `${environment.bundle}.${sku}`;
      this.iap.when(id).approved((p: any) => {
        p.finish();
        res(true);
      });
      this.iap.order(id).error((e: any) => rej(e));
    });
    return result;
  }
}
