import "firebase/remote-config";

import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Plugins, StatusBarStyle } from "@capacitor/core";
import { MenuController, Platform } from "@ionic/angular";
import { Program } from "@sophus/general";
import { Observable } from "rxjs";
import { filter, map, tap } from "rxjs/operators";

import { environment } from "../environments/environment";
import { PaymentsService } from "./core/services/payments.service";
import { PushService } from "./core/services/push.service";
import { ProgramsService } from "./programs/state/programs.service";
import { SessionService } from "./session/state/session.service";

declare global {
  interface Window {
    _sva: any;
  }
}

const { StatusBar, SplashScreen } = Plugins;

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  public showMenu$: Observable<boolean>;
  public programs$: Observable<Program[]>;
  public ios: boolean;
  public environment: any;
  public toggle: boolean;

  constructor(
    private menuCtrl: MenuController,
    private router: Router,
    private platform: Platform,
    private sessionService: SessionService,
    private programsService: ProgramsService,
    private paymentsService: PaymentsService,
    private pushService: PushService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.environment = environment;
    this.ios = this.platform.is("ios");
    this.toggle = false;
    this.programs$ = this.programsService.sync();

    // sync session info for life of app
    this.sessionService.sync().subscribe();
    this.programsService.sync().subscribe();
    this.pushService.sync().subscribe();

    // only show the side menu on select pages
    this.showMenu$ = this.router.events.pipe(
      filter((e) => e instanceof NavigationEnd),
      tap(() => this.retargetSurvicate()),
      map((e: NavigationEnd) => e.urlAfterRedirects),
      map((url) => url.startsWith("/settings") || url.startsWith("/tabs"))
    );

    // stop here if we are not running on device
    if (!this.platform.is("capacitor")) return;

    // set statusbar styles
    StatusBar.setStyle({ style: StatusBarStyle.Dark });
    StatusBar.setBackgroundColor({ color: environment.shade });

    // hide splash screen
    SplashScreen.hide();

    // setup payments
    await this.paymentsService.init();
  }

  public close(): Promise<boolean> {
    return this.menuCtrl.close();
  }

  public async signOut(): Promise<void> {
    await this.close();
    return this.sessionService.signOut();
  }

  /**
   * Shows a specific feedback survey via Survicate. We're using a "magic"
   * string here for the ID which is not ideal but will suffice until we hit v3.
   */
  public async showFeedbackSurvey(): Promise<boolean> {
    await this.close();
    if (!window._sva) return false;
    window._sva.showSurvey("f739a48b58f7e8e3", { forceDisplay: true });
  }

  /**
   * This tells Survicate to check rules again. Used every time we navigate to a
   * new page to simulate old style page reloading.
   */
  private retargetSurvicate(): void {
    if (!window._sva) return;
    window._sva.retarget();
  }
}
