<ion-header no-border>
  <ion-toolbar color="primary">
    <ion-title>Update Required</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <ion-grid>
    <ion-row>
      <ion-col class="ion-text-center">
        <h5>We've made some changes!</h5>
        <p>
          <ion-text color="medium">
            To ensure we can continue to provide you with a great experience,
            you'll need to update your app before proceeding
          </ion-text>
        </p>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="ion-text-center">
        <img
          src="assets/img/{{ app }}/undraw/download.svg"
          class="ion-margin-vertical"
        />
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col class="ion-text-center"></ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
<ion-footer no-border>
  <ion-toolbar>
    <ion-button
      expand="block"
      type="submit"
      shape="round"
      class="ion-margin"
      [href]="url"
      target="_blank"
    >
      <ion-label>Get Latest Update</ion-label>
    </ion-button>
  </ion-toolbar>
</ion-footer>
