import { Injectable } from "@angular/core";
import { QueryEntity } from "@datorama/akita";
import { UploadsStore, UploadsState } from "./uploads.store";
import { Observable, of } from "rxjs";
import { filter, switchMap } from "rxjs/operators";
import { StorageService, Upload } from "@sophus/general";

@Injectable({ providedIn: "root" })
export class UploadsQuery extends QueryEntity<UploadsState> {
  constructor(
    protected store: UploadsStore,
    private storageService: StorageService
  ) {
    super(store);
  }

  public selectDownloadURL(id: string): Observable<any> {
    if (!id) return null;
    return this.selectEntity(id).pipe(
      filter((upload) => ["id", "path"].every((k) => k in upload)),
      switchMap((upload) => {
        return this.storageService.getDownloadURL(
          `${upload.path}/${upload.id}`
        );
      })
    );
  }

  public selectAllForPost(post: string): Observable<Upload[]> {
    if (!post) return of([]);
    return this.selectAll({ filterBy: [(each) => each.post == post] });
  }

  public selectAllForContent(content: string): Observable<Upload[]> {
    if (!content) return of([]);
    return this.selectAll({ filterBy: [(each) => each.content == content] });
  }
}
