import { Injectable } from "@angular/core";
import {
  DatabaseService,
  ErrorsService,
  FilesService,
  StorageService,
  Upload,
  createUpload,
} from "@sophus/general";
import { Observable, defer } from "rxjs";
import {
  catchError,
  map,
  shareReplay,
  switchMap,
  take,
  tap,
} from "rxjs/operators";

import { UploadsQuery } from "./uploads.query";
import { UploadsStore } from "./uploads.store";

@Injectable({ providedIn: "root" })
export class UploadsService {
  constructor(
    private uploadsStore: UploadsStore,
    private uploadsQuery: UploadsQuery,
    private dbService: DatabaseService,
    private errorsService: ErrorsService,
    private filesService: FilesService,
    private storageService: StorageService
  ) {}

  public sync(id: string): Observable<Upload> {
    return this.dbService
      .sync(this.uploadsStore, "uploads", (q) => q.where("id", "==", id))
      .pipe(
        switchMap(() => this.uploadsQuery.selectEntity(id)),
        catchError((e) => this.errorsService.handleError(e))
      );
  }

  public syncAllForPost(post: string): Observable<Upload[]> {
    return this.dbService
      .sync(this.uploadsStore, "uploads", (q) => q.where("post", "==", post))
      .pipe(
        switchMap(() => this.uploadsQuery.selectAllForPost(post)),
        catchError((e) => this.errorsService.handleErrorWith(e, []))
      );
  }

  public syncAllForContent(content: string): Observable<Upload[]> {
    return this.dbService
      .sync(this.uploadsStore, "uploads", (q) =>
        q.where("content", "==", content)
      )
      .pipe(
        switchMap(() => this.uploadsQuery.selectAllForContent(content)),
        shareReplay(1),
        catchError((e) => this.errorsService.handleErrorWith(e, []))
      );
  }

  public create(file: File, path: string, data: any = {}): Observable<Upload> {
    return defer(async () => {
      return createUpload({
        id: this.dbService.createId(),
        created: new Date().getTime(),
        size: file.size,
        type: file.type,
        path: path,
        title: file.name,
        image: await this.filesService.getThumbAsBase64(file),
        ...data,
      });
    }).pipe(
      switchMap((upload) => {
        return this.storageService
          .put(`${path}/${upload.id}`, file)
          .pipe(map(() => upload));
      }),
      switchMap(async (upload) => {
        await this.dbService.set(`uploads/${upload.id}`, upload);
        return upload;
      }),
      tap((upload) => this.uploadsStore.upsert(upload.id, upload)),
      catchError((e) => this.errorsService.handleError(e))
    );
  }

  public selectDownloadUrl(upload: Upload): Observable<any> {
    return this.storageService.getDownloadURL(`${upload.path}/${upload.id}`);
  }

  public async destroy(upload: Upload): Promise<any> {
    await this.dbService.destroy(`uploads/${upload?.id}`);
    return this.storageService
      .ref(`${upload.path}/${upload.id}`)
      .delete()
      .toPromise();
  }

  public destroyAllForPost(postID: string): Observable<any> {
    return this.uploadsQuery.selectAllForPost(postID).pipe(
      take(1),
      switchMap((uploads: Upload[]) => {
        return defer(() => {
          let promises = uploads.map((each) => this.destroy(each));
          return Promise.all(promises);
        });
      }),
      catchError((e) => this.errorsService.handleError(e))
    );
  }
}
