import { Component } from "@angular/core";
import { Platform } from "@ionic/angular";
import { environment } from "projects/client/src/environments/environment";

@Component({
  selector: "app-update-required-view",
  templateUrl: "./update-required-view.component.html",
  styleUrls: ["./update-required-view.component.scss"],
})
export class UpdateRequiredViewComponent {
  app: string;
  url: string;

  constructor(private platform: Platform) {
    // set defaults
    this.app = environment.app;
    this.url = this.platform.is("ios")
      ? environment.appStoreUrl
      : environment.playStoreUrl;
  }
}
